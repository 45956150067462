import React, { ReactElement, ReactNode } from 'react'
import { MapContainer as BaseMap, TileLayer, ZoomControl } from 'react-leaflet'
// import L from 'leaflet'
import PropTypes from 'prop-types'

import { mapBase } from './map.module.css'

const isDomAvailable = typeof window !== 'undefined'

if (isDomAvailable) {
  // To get around an issue with the default icon not being set up right between using React
  // and importing the leaflet library, we need to reset the image imports
  // See https://github.com/PaulLeCam/react-leaflet/issues/453#issuecomment-410450387
  // delete L.Icon.Default.prototype._getIconUrl
  // L.Icon.Default.mergeOptions({
  //   iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  //   iconUrl: require('leaflet/dist/images/marker-icon.png'),
  //   shadowUrl: require('leaflet/dist/images/marker-shadow.png')
  // })
}

interface IMapProps {
  name?: string
  easting: number
  northing: number
  zoom: number
  children: ReactNode
}

export const Map = ({ easting, northing, zoom, children }: IMapProps): ReactElement => {
  if (!isDomAvailable) {
    return (
      <div>
        <p>Loading map...</p>
      </div>
    )
  }

  const mapSettings = {
    className: mapBase,
    zoomControl: false,
    center: [easting, northing],
    zoom: zoom
  }

  return (
    <div className="MapContainer">
      <BaseMap {...mapSettings}>
        <TileLayer
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        />
        <ZoomControl position="bottomright" />
        {children}
      </BaseMap>
    </div>
  )
}

Map.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  defaultBaseMap: PropTypes.string,
  mapEffect: PropTypes.func
}

export default Map
