import React, { ReactElement } from 'react'
// function Hero(): ReactElement {
//   return (
//     <div className="mx-auto max-w-7xl h-96">
//       <div>
//         <StaticImage
//           src="../images/banner-back-pain.jpg"
//           alt="Christer Førland"
//           layout="fullWidth"
//           // aspectRatio={1}
//           height={200}
//           // width={150}
//           objectFit="cover"
//           className="w-full"
//           // objectPosition="0% 50%"
//           // transformOptions={{ grayscale: true }}
//           // imgClassName="shadow-lg"
//           // imgStyle={{ borderRadius: '50%' }}
//         />
//       </div>
//     </div>
//   )
// }
// export default Hero
// import { graphql, useStaticQuery } from 'gatsby'
// import { StaticImage } from 'gatsby-plugin-image'
// import { getImage } from 'gatsby-plugin-image'
// import { BgImage } from 'gbimage-bridge'
// const Hero = () => {
//   const { placeholderImage } = useStaticQuery(
//     graphql`
//       query {
//         placeholderImage: file(relativePath: { eq: "images/banner-back-pain.jpg" }) {
//           childImageSharp {
//             fluid(quality: 90, maxWidth: 1920) {
//               ...GatsbyImageSharpFluid_withWebp
//             }
//           }
//         }
//       }
//     `
//   )
//   const pluginImage = getImage(placeholderImage)
//   return (
//     <BgImage image={pluginImage} style={{ minWidth: 200, minHeight: 200 }}>
//       <div>Hello from BgImage!</div>
//     </BgImage>
//   )
// }
// export default Hero
import { graphql, useStaticQuery } from 'gatsby'
import BackgroundImage from 'gatsby-background-image-es5'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { convertToBgImage } from 'gbimage-bridge'

import { OnlineBooking, SocialPhone } from './social'

const Hero = () => {
  const { placeholderImage } = useStaticQuery(
    graphql`
      query {
        placeholderImage: file(relativePath: { eq: "banner-back-pain-1.jpg" }) {
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP, AVIF], layout: FULL_WIDTH, height: 300)
          }
        }
      }
    `
  )
  const image = getImage(placeholderImage)

  // Use like this:
  const bgImage = convertToBgImage(image)

  return (
    <>
      <div className="relative border-b border-gray-200 border-solid">
        <div className="mx-auto w-full max-w-4xl opacity-50 vignette-white">
          <BackgroundImage
            Tag="section"
            // Spread bgImage into BackgroundImage:
            {...bgImage}
            preserveStackingContext
          >
            <div
              // style={{ minHeight: 300 }}
              className="flex flex-col justify-center px-4 sm:px-6 space-y-6 min-h-[220px] md:min-h-[300px]"
            >
              {/* <h1 className="block w-80 font-medium text-primary filter drop-shadow-lg">
              Velkommen til Kiropraktor Akutten!
            </h1>
            <h2 className="block w-96 font-medium text-primary-light filter drop-shadow-lg">
              Din kiropraktor i Stavanger og Sandnes!
            </h2>
            <GatsbyImage image={image} alt={'testimage'} /> */}
            </div>
          </BackgroundImage>
          {/* <div className="absolute right-0 bottom-0 w-80 h-40 bg-gradient-to-tl from-primary-light opacity-10 backdrop-blur-xl"></div> */}
        </div>
        {/* <div className="absolute top-0 right-0 bottom-0 w-96 bg-gradient-to-l from-primary-light opacity-10 "></div>
      <div className="absolute top-0 bottom-0 left-0 w-96 bg-gradient-to-r from-primary-light opacity-10"></div> */}
        <div
          // style={{ minHeight: 300 }}
          className="flex absolute top-0 right-0 bottom-0 left-0 flex-col justify-center px-4 lg:px-0 mx-auto -mt-8 md:mt-0 space-y-4 md:space-y-6 max-w-4xl min-h-[220px] md:min-h-[300px] text-2xl md:text-4xl"
        >
          {/* <h1 className="block w-80 font-medium text-primary filter drop-shadow-lg">
            Velkommen til Kiropraktor Akutten!
          </h1> */}
          {/* <h2 className="block w-96 font-medium text-primary-light filter drop-shadow-lg">
            Din kiropraktor i Stavanger og Sandnes!
          </h2>           */}
          <h1 className="block w-96 font-medium leading-relaxed text-primary-light filter drop-shadow-lg ">
            Din kiropraktor i Stavanger og Sandnes!
          </h1>
          {/* <div className="grid grid-cols-1 md:grid-cols-2 gap-4 justify-start mx-auto md:mx-0 mt-[28px] md:mt-0 w-48 md:max-w-md h-12 text-sm md:text-lg">
            <SocialPhone variant="button" />
            <OnlineBooking variant="button-light" />
          </div> */}
        </div>
      </div>
      <div className="flex z-10 justify-center md:justify-start md:px-4 lg:px-0 mx-auto -mt-5 space-x-4 md:space-x-8 w-full max-w-4xl">
        <SocialPhone variant="button" />
        <OnlineBooking variant="button-light" />
      </div>
    </>
  )
}
export default Hero
