import React, { ReactChild, ReactNode } from 'react'
import { Marker, Tooltip } from 'react-leaflet'
import { BsClock } from '@react-icons/all-files/bs/BsClock'
import { FaMap } from '@react-icons/all-files/fa/FaMap'
import { FaMapSigns } from '@react-icons/all-files/fa/FaMapSigns'

import { Map } from '@/components/map'

/* -------------------------------------------------------------------------- */
/*                                   TYPINGS                                  */
/* -------------------------------------------------------------------------- */
type OpeningHours = {
  day: string
  hours: string
}

type Address = {
  street: string
  streetNumber: string | number
  city: string
}

interface IClinicProps {
  name?: string
  description?: string
  easting: number
  northing: number
  zoom: number
  address: Address
  openingHours: OpeningHours[]
  // children: ReactNode
}

interface IGoogleMapLinkProps {
  easting: number
  northing: number
  zoom: number
}

interface IOpeningHoursProps {
  openingHours: OpeningHours[]
  textStyle?: string
}

const BASE_STYLES = {
  itemClass: 'text-base text-gray-500 hover:text-primary'
}

/* -------------------------------------------------------------------------- */
/*                                 COMPONENTS                                 */
/* -------------------------------------------------------------------------- */
const GoogleMapLink = ({ easting, northing, zoom }: IGoogleMapLinkProps) => {
  const url = `https://www.google.com/maps?z=${zoom}&daddr=${easting},${northing}`
  return (
    <a href={url} target="_blank" rel="noreferrer" className="underline text-primary-light">
      Åpne i Google Map
    </a>
  )
}

type FooterItemProps = {
  Icon: ReactNode
  hover: boolean
  textStyle?: string
  children: ReactChild
}

/**
 * Basic Component re-used for the footer row items
 *
 * @param {{ Icon: ReactNode; children: ReactChild }} { Icon, children }
 * @return {ReactNode}
 */
const FooterItem = ({ Icon, children, hover, textStyle }: FooterItemProps) => {
  const hoverClass = hover ? 'hover:text-primary' : ''
  const textStyleClass = textStyle ? textStyle : 'text-gray-500'

  return (
    <div className={`flex items-center text-base ${textStyleClass} ` + hoverClass}>
      <div>{Icon}</div>
      <div className="ml-4">{children}</div>
    </div>
  )
}

/**
 * Clinic address
 *
 * @param {{ address: Address }} { address }
 * @return {ReactNode}
 */
const AddressSimple = ({ address }: { address: Address }) => {
  return (
    <FooterItem hover={false} Icon={<FaMapSigns />}>
      <>
        <span>{address.street}</span>
        <span>{address.streetNumber},</span>
        <span>{address.city}</span>
      </>
    </FooterItem>
  )
}

/**
 * Component to open the map in Google Maps
 *
 * @param {IGoogleMapLinkProps} props
 * @return {ReactNode}
 */
const OpenInGMaps = (props: IGoogleMapLinkProps) => {
  return (
    <FooterItem hover={true} Icon={<FaMap />}>
      <GoogleMapLink {...props} />
    </FooterItem>
  )
}

/**
 * CLinic Opening hours
 *
 * @param {IOpeningHoursProps} { openingHours }
 * @return {ReactNode}
 */
const OpeningHours = ({ openingHours, textStyle }: IOpeningHoursProps) => {
  return (
    <>
      <FooterItem hover={false} Icon={<BsClock />} textStyle={textStyle}>
        <span>Åpningstider</span>
      </FooterItem>
      <div>
        {openingHours.map((o, idx) => (
          <div key={`${idx}.row`} className="grid grid-cols-2 text-gray-500">
            <div key={`${idx}.day`}>{o.day}</div>
            <div key={`${idx}.hours`}>{o.hours}</div>
          </div>
        ))}
      </div>
    </>
    // <div className={BASE_STYLES.itemClass}>
    //   <div>
    //     <BsClock />
    //   </div>

    // </div>
  )
}

const ClinicName = ({ name }: { name: string }) => <h3>name</h3>

export const ClinicSimple = (props: IClinicProps) => {
  const { name, description, address, openingHours, ...mapProps } = props

  return (
    <div>
      <h3 className="text-sm font-semibold tracking-wider text-gray-500 uppercase">{name}</h3>
      <div className="mt-4 space-y-4">
        <AddressSimple address={address} />
        <OpenInGMaps {...mapProps} />
        <OpeningHours openingHours={openingHours} />
      </div>
      {/* <ul role="list" className="mt-4 space-y-4">
      {navigation.solutions.map((item) => (
        <li key={item.name}>
          <a href={item.href} className="text-base text-gray-500 hover:text-primary">
            {item.name}
          </a>
        </li>
      ))}
    </ul> */}
    </div>
  )
}

export const ClinicFull = (props: IClinicProps) => {
  const { name, description, address, openingHours, ...mapProps } = props

  return (
    <div className="bg-white border border-gray-200 border-solid rounded-lg shadow">
      <h2 className="p-2 !my-0 !text-white bg-primary-light rounded-t-lg">{name}</h2>
      <div className="grid grid-cols-1 gap-4 md:grid-cols-2">
        <div className="w-full border-b border-gray-400 border-solid h-72 md:h-full md:border-r md:border-b-0 Map-Container">
          <Map {...mapProps} className="w-full h-full">
            <Marker position={[mapProps.easting, mapProps.northing]}>
              {/* <Tooltip direction="top" offset={[0, 0]} opacity={1} permanent>
                <span>Klinikk</span>
              </Tooltip> */}
            </Marker>
          </Map>
        </div>
        <div className="grid grid-cols-1 gap-4 px-4 md:py-4 Map-Info">
          <OpeningHours openingHours={openingHours} textStyle="text-gray-700" />
          <div>{description && description.split('\n').map((d, idx) => <p key={idx}>{d}</p>)}</div>
          <GoogleMapLink {...mapProps} />
        </div>
      </div>
    </div>
  )
}
