import React from 'react'

import Cookie from './cookie'
import Footer from './footer'
import Header from './header'
import Hero from './hero'
import SEO from './seo'

export default function Layout({ children }) {
  return (
    <div className="flex flex-col w-full min-h-screen">
      <SEO />
      <Header />
      <Hero />
      <div className="flex-grow px-4 sm:px-6 lg:px-8 mx-auto w-full max-w-7xl">
        <main className="mx-auto mt-8 max-w-4xl min-h-[200px] prose">{children}</main>
      </div>
      <Footer />
      <Cookie />
    </div>
  )
}
