import { isEmpty } from 'lodash'

export const routes: Routes = {
  home: {
    type: 'simple menu',
    title: 'Velkommen',
    href: '/'
  },
  omKiropraktikk: {
    type: 'simple menu',
    title: 'Om Kiropraktikk',
    href: '/om-kiropraktikk'
  },
  omOss: {
    type: 'simple menu',
    title: 'Om Oss',
    href: '/om-oss'
  },
  klinikk: {
    type: 'simple menu',
    title: 'Våre klinikker',
    href: '/klinikk'
  },
  priser: {
    type: 'simple menu',
    title: 'Priser',
    href: '/priser'
  },
  behandling: {
    type: 'simple menu',
    title: 'Behandling',
    href: '/behandling'
  },
  personligTrening: {
    type: 'simple menu',
    title: 'Personlig Trening',
    href: '/personlig-trening',
    area: 'Mer...'
  },
  forsikring: {
    type: 'simple menu',
    title: 'Forsikring',
    href: '/forsikring',
    area: 'Mer...'
  },
  bedriftOgErgonomi: {
    type: 'simple menu',
    title: 'Bedrift Og Ergonomi',
    href: '/bedrift-og-ergonomi',
    area: 'Mer...'
  },
  korsryggsmerter: {
    type: 'simple menu',
    title: 'Korsryggsmerter',
    href: '/behandlingstilbud/korsryggsmerter',
    area: 'Behandlingstilbud'
  },
  skiveutglidning: {
    type: 'simple menu',
    title: 'Skiveutglidning',
    href: '/behandlingstilbud/skiveutglidning',
    area: 'Behandlingstilbud'
  },
  nakkesmerter: {
    type: 'simple menu',
    title: 'Nakkesmerter',
    href: '/behandlingstilbud/nakkesmerter',
    area: 'Behandlingstilbud'
  },
  migrene: {
    type: 'simple menu',
    title: 'Migrene',
    href: '/behandlingstilbud/migrene',
    area: 'Behandlingstilbud'
  },
  spenningshodepine: {
    type: 'simple menu',
    title: 'Spenningshodepine',
    href: '/behandlingstilbud/spenningshodepine',
    area: 'Behandlingstilbud'
  },
  spedbarnBarnOgUngdom: {
    type: 'simple menu',
    title: 'Spedbarn Barn Og Ungdom',
    href: '/behandlingstilbud/spedbarn-barn-og-ungdom',
    area: 'Behandlingstilbud'
  },
  gravide: {
    type: 'simple menu',
    title: 'Gravide',
    href: '/behandlingstilbud/gravide',
    area: 'Behandlingstilbud'
  },
  overtraakk: {
    type: 'simple menu',
    title: 'Overtråkk',
    href: '/behandlingstilbud/overtraakk',
    area: 'Behandlingstilbud'
  },
  kneplagerLeddbaansskade: {
    type: 'simple menu',
    title: 'Kneplager Leddbaansskade',
    href: '/behandlingstilbud/kneplager-leddbaansskade',
    area: 'Behandlingstilbud'
  },
  hofteplager: {
    type: 'simple menu',
    title: 'Hofteplager',
    href: '/behandlingstilbud/hofteplager',
    area: 'Behandlingstilbud'
  },
  utstralendeSmerter: {
    type: 'simple menu',
    title: 'Utstralende Smerter',
    href: '/behandlingstilbud/utstralende-smerter',
    area: 'Behandlingstilbud'
  },
  brystryggsmerter: {
    type: 'simple menu',
    title: 'Brystryggsmerter',
    href: '/behandlingstilbud/brystryggsmerter',
    area: 'Behandlingstilbud'
  },
  skuldersmerter: {
    type: 'simple menu',
    title: 'Skuldersmerter',
    href: '/behandlingstilbud/skuldersmerter',
    area: 'Behandlingstilbud'
  },
  senebetennelse: {
    type: 'simple menu',
    title: 'Senebetennelse',
    href: '/behandlingstilbud/senebetennelse',
    area: 'Behandlingstilbud'
  },
  bekkensmerterBekkenlosning: {
    type: 'simple menu',
    title: 'Bekkensmerter Bekkenlosning',
    href: '/behandlingstilbud/bekkensmerter-bekkenlosning',
    area: 'Behandlingstilbud'
  },
  //
  dns: {
    type: 'simple menu',
    title: 'Dynamic Neuromuskular Stabilization (DNS)',
    href: '/behandlingsmetoder/dns',
    area: 'Behandlingsmetoder'
  },
  mdt: {
    type: 'simple menu',
    title: 'Mekanisk diagnose og behandling (MDT)',
    href: '/behandlingsmetoder/mdt',
    area: 'Behandlingsmetoder'
  },
  leddjustering: {
    type: 'simple menu',
    title: 'Leddjustering',
    href: '/behandlingsmetoder/leddjustering',
    area: 'Behandlingsmetoder'
  },
  klassiskRehabilitering: {
    type: 'simple menu',
    title: 'Klassisk Rehabilitering',
    href: '/behandlingsmetoder/klassisk-rehabilitering',
    area: 'Behandlingsmetoder'
  },
  ischemiskTrykk: {
    type: 'simple menu',
    title: 'Ischemisk Trykk',
    href: '/behandlingsmetoder/ischemisk-trykk',
    area: 'Behandlingsmetoder'
  },
  trykkbolgeterapi: {
    type: 'simple menu',
    title: 'Trykkbolgeterapi',
    href: '/behandlingsmetoder/trykkbolgeterapi',
    area: 'Behandlingsmetoder'
  },
  naalebehandling: {
    type: 'simple menu',
    title: 'Nålebehandling',
    href: '/behandlingsmetoder/naalebehandling',
    area: 'Behandlingsmetoder'
  }
}

export default routes

const areas = Object.values(routes).reduce((prev, cur: SimpleMenu) => {
  if (cur.area) {
    if (isEmpty(prev[cur.area])) {
      prev[cur.area] = [cur]
    } else {
      prev[cur.area].push(cur)
    }
  }
  return prev
}, {})

export const NavigationMenu: NavigationMenu = {
  mobile: [
    routes.home,
    routes.omKiropraktikk,
    routes.behandling,
    routes.omOss,
    routes.klinikk,
    routes.priser,
    routes.personligTrening,
    routes.bedriftOgErgonomi,
    routes.forsikring
    // {
    //   title: 'Behandling',
    //   type: 'nested menu',
    //   areas: ['Behandlingsmetoder', 'Behandlingstilbud'],
    //   menuItems: [routes.home]
    // }
  ],
  desktop: [
    routes.home,
    routes.omKiropraktikk,
    routes.omOss,
    routes.behandling,
    routes.klinikk,
    routes.priser,

    // {
    //   title: 'Behandling',
    //   type: 'nested menu',
    //   areas: ['Behandlingsmetoder', 'Behandlingstilbud'],
    //   menuItems: [
    //     routes.spedbarnBarnOgUngdom,
    //     routes.gravide,
    //     routes.korsryggsmerter,
    //     routes.skiveutglidning,
    //     routes.nakkesmerter,
    //     routes.migrene,
    //     routes.spenningshodepine,
    //     routes.overtraakk,
    //     routes.kneplagerLeddbaansskade,
    //     routes.hofteplager,
    //     routes.utstralendeSmerter,
    //     routes.brystryggsmerter,
    //     routes.skuldersmerter,
    //     routes.bekkensmerterBekkenlosning,
    //     routes.senebetennelse,
    //     //
    //     routes.dns,
    //     routes.mdt,
    //     routes.leddjustering,
    //     routes.klassiskRehabilitering,
    //     routes.ischemiskTrykk,
    //     routes.naalebehandling
    //   ]
    // },
    {
      title: 'Mer...',
      type: 'nested menu',
      areas: ['Mer...'],
      menuItems: [routes.personligTrening, routes.bedriftOgErgonomi, routes.forsikring]
    }
  ]
}
