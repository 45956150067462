import { graphql, useStaticQuery } from 'gatsby'

type UseSiteMetadataReturn = {
  siteUrl: string
  siteAuthor: string
  siteTitle: string
  siteDescription: string
  siteLang: string
  keywords: string[]
}

const useSiteMetadata = (): UseSiteMetadataReturn => {
  const { site } = useStaticQuery(
    graphql`
      query SITE_METADATA_QUERY {
        site {
          siteMetadata {
            siteUrl
            siteAuthor
            siteTitle
            siteDescription
            siteLang
            keywords
          }
        }
      }
    `
  )
  return site.siteMetadata
}

export default useSiteMetadata
