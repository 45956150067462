// // eslint-disable-next-line no-unused-vars
import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'

import { ClinicFull, ClinicSimple } from './clinic'

const useClinicData = () => {
  const data = useStaticQuery(graphql`
    query ClinicStavanger {
      clinicYaml(name: { eq: "Stavanger" }) {
        easting
        name
        northing
        zoom
        description
        address {
          city
          street
          streetNumber
        }
        openingHours {
          day
          hours
        }
      }
    }
  `)
  return data.clinicYaml
}

export const ClinicStavangerDetailed = () => {
  const data = useClinicData()
  return <ClinicFull {...data} />
}

export const ClinicStavangerSimple = () => {
  const data = useClinicData()
  return <ClinicSimple {...data} />
}
