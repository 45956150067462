import React, { ReactChild, ReactElement, ReactNode } from 'react'
import { IconContext } from '@react-icons/all-files'
// import { IconContext } from 'react-icons'
import { FaFacebookF } from '@react-icons/all-files/fa/FaFacebookF'
import { FaPhone } from '@react-icons/all-files/fa/FaPhone'
import { GoCalendar } from '@react-icons/all-files/go/GoCalendar'
import { MdEmail } from '@react-icons/all-files/md/MdEmail'

type ButtonLinkProps = {
  url: string
  title: string
  icon: ReactNode
  variant?: 'flat' | 'button' | 'button-light'
}

const ButtonLink = ({ url, title, icon, variant = 'flat' }: ButtonLinkProps): ReactElement => {
  let cls =
    variant === 'flat'
      ? 'text-gray-500 hover:text-primary'
      : 'w-33 md:w-48 text-white px-2 md:px-4 py-2 rounded-lg shadow-lg'

  if (variant === 'button') cls += ' bg-primary'
  if (variant === 'button-light') cls += ' bg-primary-light'

  return (
    <a href={url} className={`flex items-start justify-start ${cls}`}>
      <IconContext.Provider value={{ className: 'h-6 w-6' }}>
        <div>{icon}</div>
      </IconContext.Provider>
      <span className="ml-2 md:ml-6">{title}</span>
    </a>
  )
}

export const SocialPhone = ({ variant = 'flat' }: { variant?: ButtonLinkProps['variant'] }): ReactElement => {
  const url = 'tel:+47452 81 030'
  return <ButtonLink url={url} title="452 81 030" icon={<FaPhone />} variant={variant} />
}

export const SocialEmail = ({ variant = 'flat' }: { variant?: ButtonLinkProps['variant'] }): ReactElement => {
  const url = 'mailto:kiropraktorakutt1@gmail.com'
  return <ButtonLink url={url} title="e-post" icon={<MdEmail />} variant={variant} />
}

export const SocialFacebook = ({ variant = 'flat' }: { variant?: ButtonLinkProps['variant'] }): ReactElement => {
  const url = 'https://www.facebook.com/KiropraktorAkutt1/'
  return <ButtonLink url={url} title="Facebook" icon={<FaFacebookF />} variant={variant} />
}

export const OnlineBooking = ({ variant = 'flat' }: { variant?: ButtonLinkProps['variant'] }): ReactElement => {
  const url = 'https://timebestilling.aspit.no/#/p2207/main'
  return <ButtonLink url={url} title="Bestill Online" icon={<GoCalendar />} variant={variant} />
}
