import React, { Fragment } from 'react'
import { Link } from 'gatsby'
import { Popover, Transition } from '@headlessui/react'
import { MenuIcon } from '@heroicons/react/outline'
import { ChevronDownIcon } from '@heroicons/react/solid'

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ')
}

const MobileHamburgerMenu = () => {
  return (
    <div className="lg:hidden -my-2 -mr-2">
      <Popover.Button className="inline-flex justify-center items-center p-2 text-gray-400 hover:text-gray-500 bg-white hover:bg-gray-100 rounded-md focus:ring-2 focus:ring-inset focus:ring-primary focus:outline-none">
        <span className="sr-only">Open menu</span>
        <MenuIcon className="w-6 h-6" aria-hidden="true" />
      </Popover.Button>
    </div>
  )
}

interface SimpleMenuProps {
  item: SimpleMenu
}
export const SimpleMenu = ({ item }: SimpleMenuProps) => {
  return (
    <Link
      to={item.href}
      activeClassName="active"
      className="pb-2 text-base font-medium text-gray-500 hover:text-primary border-b-2 border-white hover:border-primary border-solid transition-colors duration-300"
    >
      {item.title}
    </Link>
  )
}

interface NestedMenuProps {
  menu: NestedMenu
}

interface NestedMenuAreaProps {
  areas: string[]
  area: string
  index: number
  menuItems: NestedMenu['menuItems']
}

const NestedMenuArea = ({ areas, area, menuItems, index }: NestedMenuAreaProps) => {
  const BgClass = index % 2 === 0 || areas.length == 0 ? 'bg-white' : 'bg-gray-50 border-t border-gray-300'
  const gridClass = menuItems.length > 5 ? 'md:grid-cols-2' : ''
  return (
    <div className={`relative grid gap-6 ${BgClass} px-5 py-6 sm:gap-4 sm:p-4 ${gridClass}`}>
      {areas.length > 1 && (
        <h3 className="md:col-span-2 text-sm font-medium tracking-wide text-gray-500 uppercase">{area}</h3>
      )}
      {menuItems.map((item) => (
        <Link
          key={item.title}
          to={item.href}
          activeClassName="active"
          className="flex items-start p-3 -m-3 hover:bg-gray-50 rounded-lg"
        >
          {item.icon && <item.icon className="flex-shrink-0 w-6 h-6 text-gray-900" aria-hidden="true" />}
          <div className="ml-4">
            <p className="text-base font-medium ">{item.title}</p>
            {item.description && <p className="mt-1 text-sm text-gray-500">{item.description}</p>}
          </div>
        </Link>
      ))}
    </div>
  )
}

export const NestedMenu = ({ menu }: NestedMenuProps) => {
  // TODO: improve this function
  const areas: RouteAreas = menu.menuItems.reduce(function (r, a) {
    if (a.area) {
      r[a.area] = r[a.area] || []
      r[a.area].push(a)
    }
    return r
  }, Object.create(null))

  return (
    <Popover className="relative">
      {({ open }) => (
        <>
          <Popover.Button
            className={classNames(
              open ? 'text-gray-900' : 'text-gray-500',
              'group bg-white rounded-md inline-flex items-center text-base font-medium hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary'
            )}
          >
            <span>{menu.title}</span>
            <ChevronDownIcon
              className={classNames(open ? 'text-gray-600' : 'text-gray-400', 'ml-2 h-5 w-5 group-hover:text-gray-500')}
              aria-hidden="true"
            />
          </Popover.Button>

          <Transition
            show={open}
            as={Fragment}
            enter="transition ease-out duration-200"
            enterFrom="opacity-0 translate-y-1"
            enterTo="opacity-100 translate-y-0"
            leave="transition ease-in duration-150"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 translate-y-1"
          >
            <Popover.Panel
              static
              className="absolute right-0 z-10 px-2 sm:px-0 mt-3 -ml-4 lg:ml-0 w-56 max-w-xl transform lg:-translate-x-1/2"
            >
              <div className="overflow-hidden rounded-lg ring-1 ring-black ring-opacity-5 shadow-lg">
                <div className="grid relative gap-6 sm:gap-0 sm:p-0 py-6 px-5 bg-white">
                  {menu.areas.map((area: string, index: number) => (
                    <NestedMenuArea areas={menu.areas} area={area} menuItems={areas[area]} index={index} key={index} />
                  ))}

                  {/* {menu.menuItems.map((item) => (
                    <a
                      key={item.title}
                      href={item.href}
                      className="-m-3 p-3 flex items-start rounded-lg hover:bg-gray-50"
                    >
                      {item.icon && <item.icon className="flex-shrink-0 h-6 w-6 text-indigo-600" aria-hidden="true" />}
                      <div className="ml-4">
                        <p className="text-base font-medium text-gray-900">{item.title}</p>
                        {item.description && <p className="mt-1 text-sm text-gray-500">{item.description}</p>}
                      </div>
                    </a>
                  ))} */}
                </div>
              </div>
            </Popover.Panel>
          </Transition>
        </>
      )}
    </Popover>
  )
}

interface NavigationMenuProps {
  navigationMenu: NavigationMenu
}

function Navigation({ navigationMenu }: NavigationMenuProps) {
  return (
    <>
      {/* Mobile hamburger menu */}
      <MobileHamburgerMenu />
      <Popover.Group as="nav" className="hidden lg:flex md:justify-between md:px-6 md:pt-6 md:pb-3 space-x-10">
        {navigationMenu.desktop.map((item, idx) => {
          if (item.type == 'nested menu') {
            return <NestedMenu menu={item} key={idx} />
          } else {
            return <SimpleMenu item={item} key={idx} />
          }
        })}
      </Popover.Group>
    </>
  )
}

export default Navigation
