import React, { ReactElement } from 'react'
import CookieConsent from 'react-cookie-consent'

function cookie(): ReactElement {
  return (
    <CookieConsent
      location="bottom"
      buttonText="Den er greit!"
      declineButtonText="Nei!"
      cookieName="gatsby-gdpr-google-analytics"
      enableDeclineButton
      containerClasses="!bg-primary !flex !items-center !justify-center !w-screen"
      buttonClasses="!rounded-lg !px-4 !py-2 !bg-green-500 !text-white"
      declineButtonClasses="!rounded-lg !px-4 !py-2 !bg-red-500 !text-white"
      // contentClasses="bg-primary"
    >
      {/* This site uses cookies ... */}
      Dette nettstedet benytter informasjonskapsler (cookies) for å forbedre din brukeropplevelse. Ved å fortsette å
      bruke nettstedet aksepterer du disse vilkårene.
    </CookieConsent>
  )
}

export default cookie
