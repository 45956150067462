/* This example requires Tailwind CSS v2.0+ */
import React, { Fragment } from 'react'
import { Popover, Transition } from '@headlessui/react'
import {
  BookmarkAltIcon,
  CalendarIcon,
  ChartBarIcon,
  CursorClickIcon,
  MenuIcon,
  PhoneIcon,
  PlayIcon,
  RefreshIcon,
  ShieldCheckIcon,
  SupportIcon,
  ViewGridIcon,
  XIcon
} from '@heroicons/react/outline'
import { ChevronDownIcon } from '@heroicons/react/solid'

import { NavigationMenu } from '../routes'
import Navigation, { NestedMenu, SimpleMenu } from './navigation'

import useSiteMetadata from '@/hooks/useSiteMetadata'
import SiteLogo from '@/images/site-logo.inline.svg'

const solutions = [
  {
    name: 'Analytics',
    description: 'Get a better understanding of where your traffic is coming from.',
    href: '#',
    icon: ChartBarIcon
  },
  {
    name: 'Engagement',
    description: 'Speak directly to your customers in a more meaningful way.',
    href: '#',
    icon: CursorClickIcon
  },
  {
    name: 'Security',
    description: "Your customers' data will be safe and secure.",
    href: '#',
    icon: ShieldCheckIcon
  },
  {
    name: 'Integrations',
    description: "Connect with third-party tools that you're already using.",
    href: '#',
    icon: ViewGridIcon
  },
  {
    name: 'Automations',
    description: 'Build strategic funnels that will drive your customers to convert',
    href: '#',
    icon: RefreshIcon
  }
]
const callsToAction = [
  { name: 'Watch Demo', href: '#', icon: PlayIcon },
  { name: 'Contact Sales', href: '#', icon: PhoneIcon }
]
const resources = [
  {
    name: 'Help Center',
    description: 'Get all of your questions answered in our forums or contact support.',
    href: '#',
    icon: SupportIcon
  },
  {
    name: 'Guides',
    description: 'Learn how to maximize our platform to get the most out of it.',
    href: '#',
    icon: BookmarkAltIcon
  },
  {
    name: 'Events',
    description: 'See what meet-ups and other events we might be planning near you.',
    href: '#',
    icon: CalendarIcon
  },
  {
    name: 'Security',
    description: 'Understand how we take your privacy seriously.',
    href: '#',
    icon: ShieldCheckIcon
  }
]
const recentPosts = [
  { id: 1, name: 'Boost your conversion rate', href: '#' },
  {
    id: 2,
    name: 'How to use search engine optimization to drive traffic to your site',
    href: '#'
  },
  { id: 3, name: 'Improve your customer experience', href: '#' }
]

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function Header() {
  const { siteTitle } = useSiteMetadata()
  return (
    <Popover className="relative bg-white border-b-2 border-gray-100">
      {({ open }) => (
        <>
          <div className="px-4 sm:px-6 mx-auto max-w-7xl ">
            <div className="flex lg:grid justify-between lg:justify-center items-center py-6 lg:pb-0 mx-auto lg:space-y-2 lg:space-x-0 max-w-4xl">
              <div className=" md:mt-4 h-6 lg:h-16">
                <a href="/">
                  <span className="sr-only">{siteTitle}</span>
                  <SiteLogo />
                  {/* <div className="h-24">
                </div> */}
                  {/* <img
                    className="w-auto h-8 sm:h-10"
                    src="https://tailwindui.com/img/logos/workflow-mark-indigo-600.svg"
                    alt=""
                  /> */}
                </a>
              </div>
              <Navigation navigationMenu={NavigationMenu} />
            </div>
          </div>

          <Transition
            show={open}
            as={Fragment}
            enter="duration-200 ease-out"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="duration-100 ease-in"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <Popover.Panel
              focus
              static
              className="md:hidden absolute inset-x-0 top-0 z-[1000] p-2 transition transform origin-top-right"
            >
              <div className="bg-white rounded-lg divide-y-2 divide-gray-50 ring-1 ring-black ring-opacity-5 shadow-lg Mobile-Menu">
                <div className="px-5 pt-5 pb-6">
                  <div className="flex justify-between items-center">
                    <div>
                      <SiteLogo />
                    </div>
                    <div className="-mr-2">
                      <Popover.Button className="inline-flex justify-center items-center p-2 text-gray-400 hover:text-gray-500 bg-white hover:bg-gray-100 rounded-md focus:ring-2 focus:ring-inset focus:ring-primary focus:outline-none">
                        <span className="sr-only">Close menu</span>
                        <XIcon className="w-6 h-6" aria-hidden="true" />
                      </Popover.Button>
                    </div>
                  </div>
                  <div className="mt-6 bg-white">
                    <nav className="grid grid-cols-1 sm:grid-cols-2 gap-8 Navigation-Mobile">
                      {NavigationMenu.mobile.map((item, idx) => {
                        if (item.type == 'nested menu') {
                          return <NestedMenu menu={item} key={idx} />
                        } else {
                          return <SimpleMenu item={item} key={idx} />
                        }
                      })}
                    </nav>
                  </div>
                </div>
              </div>
            </Popover.Panel>
          </Transition>
        </>
      )}
    </Popover>
  )
}
