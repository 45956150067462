/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React, { ReactElement } from 'react'
import { Helmet } from 'react-helmet'

import useSiteMetadata from '@/hooks/useSiteMetadata'

interface SEOProps {
  //   lang: 'en',
  meta: any[]
  //   keywords: [],
  //   description: ''
}

function SEO({ meta = [] }: SEOProps): ReactElement {
  const siteMetadata = useSiteMetadata()

  const metaDescription = siteMetadata.siteDescription

  // const metaKeywords = [...siteMetadata.keywords, ...keywords]
  const metaKeywords = siteMetadata.keywords

  const metaLang = siteMetadata.siteLang
  // const metaLang = siteMetadata.siteLang || lang

  return (
    <Helmet
      htmlAttributes={{
        lang: metaLang
      }}
      title={siteMetadata.siteTitle}
      // title={title}
      // titleTemplate={`%s | ${siteMetadata.siteTitle}`}
      meta={[
        {
          name: 'description',
          content: metaDescription
        },
        {
          name: 'keywords',
          content: metaKeywords.join(', ')
        },
        {
          property: 'og:title',
          content: siteMetadata.siteTitle
          // content: title
        },
        {
          property: 'og:description',
          content: metaDescription
        },
        {
          property: 'og:type',
          content: 'website'
        },
        {
          name: 'twitter:card',
          content: 'summary'
        },
        {
          name: 'twitter:creator',
          content: siteMetadata.siteAuthor
        },
        {
          name: 'twitter:title',
          content: siteMetadata.siteTitle
        },
        {
          name: 'twitter:description',
          content: metaDescription
        }
      ].concat(meta)}
    />
  )
}

// SEO.defaultProps = {
//   lang: 'en',
//   meta: [],
//   keywords: [],
//   description: ''
// }

// SEO.propTypes = {
//   description: PropTypes.string,
//   lang: PropTypes.string,
//   meta: PropTypes.arrayOf(PropTypes.object),
//   keywords: PropTypes.arrayOf(PropTypes.string),
//   title: PropTypes.string.isRequired
// }

export default SEO
